export const installationFormKeys = {
  company_name: '',
  business_type: '',
  base_currency: null,
  base_currency_ref: null,
  no_of_employees: '',
  company_email: '',
  company_phone: '',
  place: '',
  address: '',
  first_name: '',
  last_name: '',
  user_email: '',
  user_phone: '',
  username: '',
  password: '',
  c_password: '',
  trn_no: '',
  tax_group: null,
  tax_group_ref: null,
  company_logo: null
};
export const RentalContractAdditionalInvoiceFormData = {
  stock_id: '',
  item_ref: '',
  item: '',
  item_display_name: '',
  id: '',
  is_kit: '0',
  quantity: '1',
  unit_price: '0.00',
  description: '',
  line_total: '0.00'
};
export const loginFormKeys = {
  username: '',
  password: ''
};

export const accountRecoveryFormKeys = {
  username: ''
};

export const resetPasswordFormKeys = {
  password: '',
  c_password: ''
};

export const bankAccountFormKeys = {
  account_code: '',
  account_name: '',
  account_type: 'cash',
  account_type_ref: { value: 'cash', label: 'Cash' },
  account_number: '',
  bank_name: '',
  bank_currency_code: null,
  bank_currency_code_ref: null,
  bank_address: '',
  branch_name: '',
  iban: ''
};

export const bankTransferFormKeys = {
  amount: '',
  bank_charges: '',
  bank_charges_method: '',
  cost_center: '',
  cost_center_ref: null,
  from_bank_account_id: '',
  from_bank_account_id_ref: null,
  memo: '',
  to_bank_account_id: '',
  to_bank_account_id_ref: null,
  trans_date: ''
};

export const costCenterFormKeys = {
  name: '',
  description: ''
};

export const ChartOfAccountsFormKeys = {
  name: '',
  opening_balance: '',
  opening_balance_date: '',
  code: '',
  typ: null,
  parent_code: null
};

export const purchaseOrderFormKeys = {
  supplier_id: null,
  supplier_id_ref: null,
  memo: '',
  phone: '',
  email: '',
  trans_date: '',
  due_date: '',
  payment_term_id: null,
  payment_term_ref: null,
  cost_center_id: null,
  cost_center_id_ref: null,
  warehouse_id: null,
  warehouse_id_ref: null,
  tax_included: 0,
  details: [],
  files: []
};

export const purchaseOrderTableFormKeys = {
  stock_id: '',
  item_display_name: null,
  item_display_name_ref: null,
  item_description: '',
  quantity: '',
  unit_price: '',
  discount_amount: '',
  discount_percent: '',
  discount_unit_ref: '$',
  unit_ref: null
};

export const purchaseInvoiceFormKeys = {
  payment_term_id: null,
  payment_term_ref: null,
  supplier_id: null,
  supplier_id_ref: null,
  supplier_reference: '',
  memo: '',
  terms_and_conditions: '',
  phone: '',
  email: '',
  trans_date: '',
  due_date: '',
  cost_center_id: null,
  cost_center_id_ref: null,
  warehouse_id: null,
  warehouse_id_ref: null,
  purchase_order_id: null,
  delivery_id: null,
  from: null,
  tax_included: 0,
  round_off_amount: 0,
  details: [],
  gl_items: [],
  files: []
};

export const purchaseInvoiceTableFormKeys = {
  stock_id: '',
  item_display_name: null,
  item_display_name_ref: null,
  item_description: '',
  quantity: '',
  unit_price: '',
  discount_amount: '',
  discount_percent: '',
  discount_unit_ref: '$',
  unit_ref: null,
  batch_number: null,
  batch_number_ref: null,
  tax_group_id: null,
  tax_group_id_ref: null
};

export const purchaseInvoiceGLItemsTableForm = {
  account_code_option_ref: '',
  account_code: '',
  account_code_ref: undefined,
  amount: '',
  description: '',
  cost_center_id: '',
  cost_center_id_ref: undefined,
  tax_group_id: '',
  tax_group_id_ref: undefined,
  total_ref: undefined
};

export const purchaseDeliveryFormKeys = {
  payment_term_id: null,
  payment_term_ref: null,
  supplier_id: null,
  supplier_id_ref: null,
  memo: '',
  phone: '',
  email: '',
  trans_date: '',
  due_date: '',
  cost_center_id: null,
  cost_center_id_ref: null,
  warehouse_id: null,
  warehouse_id_ref: null,
  purchase_order_id: null,
  from: null,
  tax_included: 0,
  details: [],
  files: []
};

export const purchaseDeliveryTableFormKeys = {
  stock_id: '',
  item_display_name: null,
  item_display_name_ref: null,
  item_description: '',
  quantity: '',
  unit_price: '',
  discount_amount: '',
  discount_percent: '',
  discount_unit_ref: '$',
  unit_ref: null
};

export const purchaseRequestFormKeys = {
  memo: '',
  trans_date: '',
  cost_center_id: null,
  cost_center_id_ref: null,
  warehouse_id: null,
  warehouse_id_ref: null,
  tax_included: 0,
  details: [],
  files: []
};

export const purchaseRequestTableFormKeys = {
  stock_id: '',
  item_display_name: null,
  item_display_name_ref: null,
  item_description: '',
  quantity: '',
  unit_price: '',
  discount_amount: '',
  discount_percent: '',
  discount_unit_ref: '$',
  unit_ref: null
};

export const purchaseDebitNoteFormKeys = {
  person_type: 'supplier',
  person_id: null,
  person_id_ref: null,
  payment_term_id: null,
  payment_term_id_ref: null,
  memo: '',
  mobile: '',
  email: '',
  trans_date: '',
  due_date: '',
  cost_center_id: null,
  cost_center_id_ref: null,
  tax_included: 0,
  round_off_amount: 0,
  details: [],
  files: []
};

export const voidTransactionsFilterFormKeys = {
  transaction_type: null,
  transaction_type_ref: null,
  reference: '',
  from_date: null,
  to_date: null
};

export const customizeReportFormKeys = {
  date_from: null,
  date_to: null
};

export const warehouseFormKeys = {
  location_name: '',
  delivery_address: '',
  phone: '',
  phone2: '',
  fax: '',
  email: '',
  contact: '',
  fixed_asset: '',
  inactive: 1,
  cost_center_id: '',
  cost_center_id_ref: ''
};

export const ledgerReportFormKeys = {
  account_code: null,
  account_code_ref: null,
  date_from: null,
  date_to: null
};
export const rolesPermissionsFilterFormKeys = {
  role: null,
  role_ref: null
};
export const UsersPermissionsFilterFormKeys = {
  user: null,
  user_ref: null
};

export const CompanyPreferenceFormKeys = {
  name: '',
  address: '',
  place: '',
  phone: '',
  email: '',
  official_phone: '',
  trn_no: '',
  base_currency: null,
  company_logo: '',
  rows_per_page: '',
  fax: ''
};

export const CompanyPreferenceFormErrorKeys = {
  name: '',
  address: '',
  place: '',
  phone: '',
  email: '',
  official_phone: '',
  trn_no: '',
  base_currency: null,
  company_logo: '',
  rows_per_page: '',
  fax: ''
};

export const supplierPopupFormKeys = {
  name: '',
  email_address: '',
  contact_number: '',
  secondary_contact_number: '',
  supplier_trn: '',
  currency_code: '',
  address: ''
};

export const paymentTermsPopupFormKeys = {
  terms_indicator: '',
  terms: '',
  days_before_due: '',
  day_in_following_month: ''
};

export const costCenterPopupFormKeys = {
  name: '',
  description: ''
};

export const warehousePopupFormKeys = {
  location_name: '',
  fax: '',
  phone: '',
  contact: '',
  phone2: '',
  email: '',
  delivery_address: ''
};

export const itemPopupFormKeys = {
  product_type: 'goods',
  stock_id: '',
  barcode: '',
  item_name: '',
  purchase_rate: '',
  sale_rate: '',
  category_id: '',
  category_id_ref: null,
  unit_id: '',
  unit_id_ref: null,
  tax_group_id: '',
  tax_group_id_ref: null,
  sales_account_code: '',
  sales_account_code_ref: null,
  purchase_account_code: '',
  purchase_account_code_ref: null,
  inventory_account_code: '',
  inventory_account_code_ref: null,
  wip_account_code: '',
  wip_account_code_ref: null,
  wip_crediting_account_code: '',
  wip_crediting_account_code_ref: null
};

export const priceFormKeys = {
  stock_id: null,
  stock_id_ref: null,
  sales_type_id: null,
  sales_type_id_ref: null,
  price: ''
};

export const unitFormKeys = {
  name: '',
  decimal: '0.00'
};

export const brandFormKeys = {
  name: '',
  description: ''
};

export const manufacturerFormKeys = {
  name: '',
  description: ''
};

export const exchangeRateFormKeys = {
  currency_code: '',
  currency_code_ref: {
    label: '',
    value: ''
  },
  rate: '',
  date: ''
};

export const fiscalYearFormKeys = {
  begin: '',
  end: '',
  closed: '',
  closed_date: ''
};
export const TermsAndConditionsFormKeys = {
  name: '',
  description: ''
};

export const modulesFormKeys = {
  module_name: '',
  description: '',
  is_active: true
};

export const paymentTermsFormKeys = {
  terms: '',
  days: '',
  inactive: false
};

export const taxFormKeys = {
  name: '',
  purchase_gl_code: '',
  purchase_gl_code_ref: {},
  sales_gl_code: '',
  sales_gl_code_ref: {}
};

export const taxGroupFormKeys = {
  name: ''
};

export const taxItemFormKeys = {
  tax_id: '',
  tax_id_ref: {},
  tax_group_id: '',
  tax_group_id_ref: {},
  tax_percentage: '',
  is_tax_exempted: 0,
  effective_from_date: ''
};

export const salesAreaFormKeys = {
  area_code: '',
  description: ''
};

export const salesTypeFormKeys = {
  name: '',
  tax_included: ''
};

export const transactionReferenceFormKeys = {
  pattern: '',
  prefix: ''
};

export const rolesFormKeys = {
  name: '',
  description: '',
  is_active: 1
};

export const shippingCompanyFormKeys = {
  name: '',
  phone: '',
  phone2: '',
  contact: '',
  address: ''
};

export const batchNumberFormKeys = {
  mfg_date: '',
  exp_date: '',
  description: ''
};

export const consumerFormKeys = {
  name: '',
  address: ''
};

export const FollowUpFormKeys = {
  person_type: '',
  person_id: '',
  subject_type: '',
  subject_id: '',
  followup_date: '',
  next_followup_date: '',
  memo: ''
};
export const FollowUpCommentsFormKeys = {
  comment_date: '',
  comment: '',
  followup_id: ''
};

export const categoryFormKeys = {
  product_type: '',
  product_type_ref: null,
  name: '',
  unit_id: '',
  unit_id_ref: null,
  tax_group_id: '',
  tax_group_id_ref: null,
  sales_account_code: '',
  sales_account_code_ref: null,
  inventory_account_code: '',
  inventory_account_code_ref: null,
  purchase_account_code: '',
  purchase_account_code_ref: '',
  wip_account_code: '',
  wip_account_code_ref: '',
  wip_crediting_account_code: '',
  wip_crediting_account_code_ref: '',
  description: ''
};

export const enquiryFormKeys = {
  date: '',
  name: '',
  phone: '',
  email: '',
  message: '',
  customer_id: '',
  customer_id_ref: '',
  salesman_id: '',
  salesman_id_ref: '',
  priority: 'Medium',
  priority_ref: {
    label: 'Medium',
    value: 'Medium'
  },
  files: []
};

export const estimateFormKeys = {
  enquiry_reference: '',
  notes: '',
  date: '',
  valid_until: '',
  salesman_id: null,
  salesman_id_ref: null,
  customer_id: null,
  customer_id_ref: null,
  estimator_id: null,
  estimator_id_ref: null,
  details: [],
  files: [],
  expected_start_date: '',
  expected_duration: ''
};

export const estimateEntryMaterialsCostsTableFormKeys = {
  stock_id: '',
  title: null,
  title_ref: null,
  description: '',
  quantity: '',
  amount: ''
};

export const estimateEntryProductInformationTableFormKeys = {
  stock_id: '',
  title: null,
  title_ref: null,
  description: '',
  quantity: '',
  dim_length: '',
  dim_width: '',
  dim_height: '',
  dimension_unit: 'cm',
  weight_unit: 'kg',
  weight: '',
  costs: [],
  additional_costs: []
};

export const workOrderFormKeys = {
  trans_date: '',
  assigned_to: null,
  assigned_to_ref: null,
  description: '',
  delivery_eta_days: '',
  sales_order_id: '',
  warehouse_id: '',
  warehouse_id_ref: null,
  project_id: '',
  project_id_ref: null,
  details: []
};

export const materialRequestFormKeys = {
  memo: '',
  trans_date: '',
  cost_center_id: null,
  cost_center_id_ref: null,
  warehouse_id: null,
  warehouse_id_ref: null,
  tax_included: 0,
  details: [],
  files: []
};

export const customerFormKeys = {
  name: '',
  customer_id: '',
  address: '',
  salesman_id: '',
  salesman_id_ref: '',
  email: '',
  phone: '',
  currency_code: '',
  currency_code_ref: {
    label: '',
    value: ''
  },
  tax_id: '',
  tax_id_ref: {
    label: '',
    value: ''
  },
  emirates_id: '',
  trade_license_no: '',
  customer_group_id: '',
  customer_group_id_ref: '',
  customer_type: 'INDIVIDUAL',
  sales_type_id: '',
  sales_type_id_ref: {
    label: '',
    value: ''
  },
  credit_status_id: '',
  credit_status_id_ref: {
    label: '',
    value: ''
  },
  payment_term_id: '',
  payment_term_id_ref: {
    label: '',
    value: ''
  },
  discount: '',
  payment_discount: '',
  credit_limit: '',
  opening_balance: '',
  opening_balance_date: '',
  notes: '',
  contact_person: '',
  inactive: '0',
  trn_no: '',
  contacts: [],
  ph_country_code: ''
};

export const projectPopupFormKeys = {
  name: '',
  start_date: '',
  end_date: '',
  price: '',
  status: '',
  status_ref: null
};

export const notificationSettingsFormKeys = {
  email: 0,
  web: 0,
  sms: 0,
  app: 0,
  message: '',
  user: null,
  user_ref: null,
  role: null,
  role_ref: null,
  permission: null,
  permission_ref: null,
  notify_to_all_users_ref: false
};

export const additionalCostFormKeys = {
  supplier_id: '',
  supplier_id_ref: null,
  supplier_reference: '',
  trans_date: '',
  account_code: '',
  account_code_ref: null,
  tax_group_id: '',
  tax_group_id_ref: null,
  unit_price: '',
  note: '',
  gl_items: [],
  bank_account_id: '',
  bank_account_id_ref: null
};

export const userDetailsFormKeys = {
  first_name: '',
  last_name: '',
  email: '',
  username: '',
  password: '',
  c_password: '',
  phone: '',
  role_id: '',
  role_id_ref: '',
  language: 'EN',
  language_ref: {
    label: 'English',
    value: 'EN'
  },
  image: ''
};
export const ChatGroupAddModalFormData = {
  description: '',
  name: '',
  user: [],
  user_ref: []
};
export const RentalContractAddFromData = {
  contract_tenure: '',
  contract_tenure_ref: '',
  customer_id: '',
  customer_id_ref: '',
  sales_type_id: '',
  sales_type_id_ref: '',
  contract_from_date: '',
  contract_to_date: '',
  contract_date: '',
  amount: '',
  description: '',
  details: [{ discount_type: 'AMOUNT' }],
  warehouse_id: '',
  warehouse_id_ref: '',
  // customer_documents: '',
  stock_id: '',
  stock_id_ref: '',
  fixed_assets_type: 'vehicle',
  fixed_assets_type_ref: {
    label: 'VEHICLE',
    value: 'vehicle'
  },
  fixed_assets_type_reference: '',
  discount_amount: '',
  discount_percent: '',
  discount_type: 'AMOUNT',
  discount: ''
};

export const EmployeeAllocationAddFromData = {
  issued_at: '',
  receiver_id: '',
  receiver_id_ref: '',
  expected_return_at: '',
  note: '',
  stock_id: '',
  stock_id_ref: '',
  fixed_assets_type: 'vehicle',
  fixed_assets_type_ref: { value: 'vehicle', label: 'VEHICLE' }
};

export const fixedAssetPurchaseInvoiceFormKeys = {
  payment_term_id: null,
  payment_term_ref: null,
  supplier_id: null,
  supplier_id_ref: null,
  supplier_reference: '',
  memo: '',
  terms_and_conditions: '',
  phone: '',
  email: '',
  trans_date: '',
  due_date: '',
  cost_center_id: null,
  cost_center_id_ref: null,
  warehouse_id: null,
  warehouse_id_ref: null,
  purchase_order_id: null,
  delivery_id: null,
  from: null,
  tax_included: 0,
  round_off_amount: 0,
  details: [],
  gl_items: [],
  files: []
};

export const fixedAssetPurchaseInvoiceEntryPopUpFormKeys = {
  stock_id: '',
  item_display_name: null,
  item_display_name_ref: null,
  item_description: '',
  unit_price: '',
  discount_amount: '',
  discount_percent: '',
  discount_unit_ref: '$',
  unit_ref: null,
  fixed_assets_type: 'vehicle',
  fixed_assets_type_ref: {
    label: 'VEHICLE',
    value: 'vehicle'
  }
};
export const rentalMakeInvoiceFormKeys = {
  customer_id: '',
  customer_id_ref: '',
  trans_date: '',
  unit_price: '',
  due_date: '',
  sales_type_id: '',
  sales_type_id_ref: '',
  warehouse_id: '',
  warehouse_id_ref: '',
  payment_term_id: '',
  payment_term_id_ref: '',
  memo: '',
  contract_id: '',
  is_deferred: 0,
  details: []
};
export const additionalInvoiceFormKeys = {
  trans_type: 'SI',
  customer_id: '',
  customer_id_ref: '',
  trans_date: '',
  salesman_id: '',
  salesman_id_ref: '',
  email: '',
  mobile: '',
  sales_type_id: '',
  sales_type_id_ref: '',
  warehouse_id: '',
  warehouse_id_ref: '',
  payment_term_id: '',
  payment_term_id_ref: '',
  due_date: '',
  memo: '',
  contract_id: '',
  details: []
};
export const AddOpeningStockFormData = {
  initial_stock: '',
  initial_stock_rate: '',
  warehouse_id: '',
  warehouse_id_ref: ''
};

export const AssetPurchasePopupFormKeys = {
  trans_date: '',
  supplier_id: '',
  supplier_id_ref: '',
  supplier_reference: '',
  warehouse_id: '',
  warehouse_id_ref: '',
  details: []
};

export const CustomerProfileUpdateFormKeys = {
  commission_enabled: 0,
  commission_payable_to: '',
  commission_payable_to_ref: '',
  commission_expense_account: '',
  commission_expense_account_ref: '',
  same_customer_account: true
};

export const bulkPriceUpdateModalFormKeys = {
  module: 'sales',
  sales_type: '',
  sales_type_ref: null,
  price: '',
  target_type: '',
  target_id: '',
  target_id_ref: null
};

export const documentTypeFilterFormKeys = {
  name: '',
  type: '',
  type_ref: {
    label: '',
    value: ''
  },
  days_until_expiry: '',
  reminder_pattern: ''
};

export const CashHandoverAddFormData = {
  trans_date: '',
  cost_center_id: '',
  cost_center_id_ref: '',
  transaction_from: '',
  transaction_from_ref: '',
  transaction_to: '',
  transaction_to_ref: '',
  note: '',
  amount: ''
};

export const salesQuotationFormKeys = {
  draft_id: '',
  trans_type: 'SQ',
  customer_id: '',
  customer_id_ref: '',
  sales_type_id: '',
  sales_type_id_ref: '',
  trans_date: '',
  expected_delivery_date: '',
  cost_center_id: '',
  cost_center_id_ref: '',
  payment_term_id: '',
  payment_term_id_ref: '',
  memo: '',
  mobile: '',
  email: '',
  details: [],
  terms_and_conditions: '',
  customer_reference: '',
  salesman_id: '',
  salesman_id_ref: '',
  custom_fields: {}
};

export const salesmanFormKeys = {
  salesman_name: '',
  salesman_phone: '',
  salesman_email: '',
  provision1: '',
  provision2: '',
  break_point: ''
};

export const customerGroupFormKeys = {
  name: ''
};

export const salesOrderFormKeys = {
  customer_id: '',
  customer_id_ref: '',
  salesman_id: '',
  salesman_id_ref: '',
  trans_type: 'SO',
  draft_id: '',
  trans_date: '',
  due_date: '',
  ship_via: '',
  ship_via_ref: '',
  mobile: '',
  email: '',
  sales_type_id: '',
  sales_type_id_ref: '',
  deliver_to: '',
  cost_center_id: '',
  cost_center_id_ref: '',
  delivery_date: '',
  payment_term_id: '',
  payment_term_id_ref: '',
  delivery_address: '',
  memo: '',
  details: [],
  custom_fields: {},
  customer_shipping_address_id: ''
};

export const salesDeliveryFormKeys = {
  draft_id: '',
  trans_type: 'SD',
  customer_id: '',
  customer_id_ref: '',
  trans_date: '',
  delivery_date: '',
  due_date: '',
  ship_via: '',
  ship_via_ref: '',
  mobile: '',
  memo: '',
  from: '',
  cost_center_id: '',
  cost_center_id_ref: '',
  sales_type_id: '',
  sales_type_id_ref: '',
  payment_term_id: '',
  payment_term_id_ref: '',
  email: '',
  deliver_to: '',
  delivery_address: '',
  warehouse_id: '',
  warehouse_id_ref: '',
  salesman_id: '',
  salesman_id_ref: '',
  terms_and_conditions: '',
  details: [],
  custom_fields: {},
  customer_shipping_address_id: ''
};

export const salesInvoiceFormKeys = {
  draft_id: '',
  trans_type: 'SI',
  customer_id: '',
  customer_id_ref: '',
  salesman_id: '',
  salesman_id_ref: '',
  cost_center_id: '',
  cost_center_id_ref: '',
  memo: '',
  email: '',
  mobile: '',
  trans_date: '',
  due_date: '',
  sales_type_id: '',
  sales_type_id_ref: '',
  payment_term_id: '',
  payment_term_id_ref: '',
  from: '',
  terms_and_conditions: '',
  project_id: '',
  project_id_ref: '',
  contract_id: '',
  contract_id_ref: '',
  details: [],
  round_off_amount: 0,
  customer_shipping_address_id: '',
  customer_lpo_number: '',
  customer_lpo_date: '',
  is_deferred: 0
};

export const creditNoteFormKeys = {
  trans_type: 'CN',
  customer_id: '',
  customer_id_ref: '',
  cost_center_id: '',
  cost_center_id_ref: '',
  memo: '',
  email: '',
  mobile: '',
  trans_date: '',
  due_date: '',
  sales_type_id: '',
  sales_type_id_ref: '',
  payment_term_id: '',
  payment_term_id_ref: '',
  details: [],
  files: [],
  round_off_amount: 0
};

export const contractFormKeys = {
  trans_type: 'CT',
  customer_id: '',
  customer_id_ref: '',
  description: '',
  title: '',
  contract_type: 'monthly',
  contract_date: '',
  contract_from_date: '',
  contract_to_date: '',
  status: 'active',
  expiry_notify_before_days: '1',
  details: [],
  custom_fields: {}
};

export const journalVoucherFormKeys = {
  trans_type: '',
  trans_date: '',
  gl_trans: [],
  memo: ''
};

export const paymentVoucherFormKeys = {
  trans_type: '',
  trans_date: '',
  person_type: 'MISCELLANEOUS',
  gl_trans: [],
  person_id: '',
  person_id_ref: '',
  person_name: '',
  pay_from: '',
  pay_from_ref: '',
  memo: ''
};

export const receiptVoucherFormKeys = {
  trans_type: '',
  trans_date: '',
  person_type: 'MISCELLANEOUS',
  gl_trans: [],
  person_id: '',
  person_id_ref: '',
  person_name: '',
  receive_to: '',
  receive_to_ref: '',
  memo: ''
};

export const addBankTransferFormKeys = {
  trans_date: '',
  bank_charges: 0,
  bank_charges_type: 'percent',
  memo: '',
  from_bank_account_id: '',
  to_bank_account_id: '',
  amount: '',
  cost_center: '',
  cost_center_ref: {
    value: '',
    label: ''
  },
  from_bank_account_id_ref: '',
  to_bank_account_id_ref: ''
};

export const paymentChequeScreenFormKeys = {
  amount: '',
  trans_date: '',
  customer_id: '',
  memo: '',
  allocs: {
    customer_id: '',
    voucher_id: '',
    amount: '',
    trans_type: 'SI'
  },
  deposit_to: '',
  deposit_to_ref: {
    label: '',
    value: ''
  },
  payment_mode: 'cheque',
  cheque_number: '',
  cheque_date: '',
  cheque_bank: '',
  trans_ref: '',
  bank_charge: '',
  is_pdc: 1
};

export const rentalContractReturnFormKeys = {
  acknowledged_by: '',
  acknowledged_by_ref: {
    label: '',
    value: ''
  },
  returned_date: '',
  asset_status: 'functional',
  asset_status_ref: {
    label: 'Functional',
    value: 'functional'
  },
  note: ''
};

export const fixedAssetFormKeys = {
  stock_id: '',
  item_name: '',
  item_description: '',
  sales_rate: '',
  unit_id: '',
  unit_id_ref: '',
  category_id: '',
  category_id_ref: '',
  sub_category_id: '',
  sub_category_ref: '',
  product_type: 'fixed_asset',
  fixed_asset_type: 'vehicle',
  fixed_asset_type_ref: { label: 'VEHICLE', value: 'vehicle' },
  fixed_asset_type_reference: '',
  item_type: 'inventory',
  sales_account_code: '',
  sales_account_code_ref: '',
  purchase_account_code: '',
  purchase_account_code_ref: '',
  inventory_account_code: '',
  inventory_account_code_ref: '',
  tax_group_id: '',
  tax_group_id_ref: '',
  purchase_rate: '',
  fa_status: 'functional',
  fa_status_ref: {
    label: 'Functional',
    value: 'functional'
  },
  fa_maintenance_period_ref: '',
  fa_maintenance_start_date: '',
  serial_number: '',
  sale_rate: ''
};
