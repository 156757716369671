import { React, useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap';
import { QRCodeCanvas } from 'qrcode.react';
import PropTypes from 'prop-types';

import { MdOutlineQrCode2, MdOutlineRefresh } from 'react-icons/md';
import { FaArrowLeft } from 'react-icons/fa';

import { showToast } from 'module/Common/Toast/toast';

function QrCodeModal({ show, handleClose }) {
  const [qrCode, setQrCode] = useState({});
  const [loading, setLoading] = useState(false);
  const [expired, setExpired] = useState(false);

  const generateQRCode = () => {
    axios
      .post('/auth/login-with-qr')
      .then(res => {
        if (res?.status === 200) {
          if (res?.data?.data?.data?.channel_id) {
            window.Echo.channel(`${res?.data?.data?.data?.channel_id}`).listen(
              'AcodaxMFABroadCast',
              data => {
                if (data?.type === 'mfa-login' && data?.auth_token) {
                  let userToken = {
                    token: data?.auth_token
                  };
                  localStorage.setItem(
                    'AXIS_PRO_USER',
                    JSON.stringify(userToken)
                  );
                  localStorage.setItem('ACODAX_MANUEL_LOGOUT', false);
                  window.location.reload();
                }
              }
            );
          }
          const jsonString = JSON.stringify(res?.data?.data?.data?.qr);
          const base64String = btoa(jsonString);
          setQrCode(base64String);
        }
      })
      .catch(error => {
        if (error) {
          showToast(
            'Something went wrong! Please contact admin for support',
            'error'
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    let interval;
    let attemptCount = 0;

    if (show) {
      setLoading(true);
      generateQRCode();
      attemptCount++;

      interval = setInterval(() => {
        if (attemptCount >= 4) {
          clearInterval(interval);
          setExpired(true);
          return;
        }
        generateQRCode();
        attemptCount++;
      }, 15000);
    }

    return () => clearInterval(interval);
  }, [show]);

  const refreshQRCode = async () => {
    setExpired(false);
    setLoading(true);

    try {
      const res = await axios.post('/auth/login-with-qr');
      if (res?.status === 200) {
        if (res?.data?.data?.data?.channel_id) {
          window.Echo.channel(`${res?.data?.data?.data?.channel_id}`).listen(
            'AcodaxMFABroadCast',
            data => {
              if (data?.type === 'mfa-login' && data?.auth_token) {
                let userToken = {
                  token: data?.auth_token
                };
                localStorage.setItem(
                  'AXIS_PRO_USER',
                  JSON.stringify(userToken)
                );
                localStorage.setItem('ACODAX_MANUEL_LOGOUT', false);
                window.location.reload();
              }
            }
          );
        }
        const jsonString = JSON.stringify(res?.data?.data?.data?.qr);
        const base64String = btoa(jsonString);
        setQrCode(base64String);
      }
    } catch (error) {
      if (error) {
        showToast(
          'Something went wrong! Please contact admin for support',
          'error'
        );
      }
    } finally {
      setLoading(false);
    }

    setTimeout(() => {
      setExpired(true);
    }, 15000);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="md"
      className="qr-code-modal"
    >
      <Modal.Header className="border-0">
        <div
          style={{
            position: 'absolute',
            top: '-35px',
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: 'white',
            padding: '10px',
            borderRadius: '50%',
            boxShadow: '0px 4px 6px rgba(0,0,0,0.1)'
          }}
        >
          <MdOutlineQrCode2 className="text-dark" size={50} />
        </div>
      </Modal.Header>
      <Modal.Body className="border-0 qr-code-modal-body gap-3 qr-container">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <span className="content-1">Scan QR code</span>
          <span className="content-2">
            Scan this QR code to access your account.
          </span>
        </div>
        {loading ? (
          <div className="qr-loading-container">
            <div className="qr-loading">
              {Array.from({ length: 64 }).map((_, index) => (
                <div key={index} className="qr-block"></div>
              ))}
            </div>
          </div>
        ) : (
          <div className="qr-code-container">
            <QRCodeCanvas value={qrCode} size={256} />
            {expired && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  background: 'rgba(0, 0, 0, 0.6)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  color: 'white'
                }}
              >
                <p className="m-0">{'QR Code Expired'}</p>
                <p className="mb-1">{'Click here to refresh'}</p>
                <button
                  onClick={() => refreshQRCode()}
                  style={{
                    padding: 'p-0',
                    background: 'transparent',
                    border: 'none',
                    color: '#fff',
                    cursor: 'pointer',
                    borderRadius: '5px',
                    fontSize: '14px'
                  }}
                >
                  <MdOutlineRefresh size={60} />
                </button>
              </div>
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="back-button">
        <Button
          variant="transparent"
          onClick={handleClose}
          className="w-100 fs--1"
        >
          Login using credentials
          <FaArrowLeft className="ms-2" size={15} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

QrCodeModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func
};

export default QrCodeModal;
