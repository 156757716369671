import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import { CloseButton, Dropdown, Form } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import countryCodes from '../../assets/json/countryCodes.json';
import useAxisproTranslate from 'hooks/useAxisproTranslate';

const SelectCountryCode = ({
  onChange,
  value,
  name,
  className = '',
  isRequired = false
}) => {
  const Translate = useAxisproTranslate();
  const [searchTerm, setSearchTerm] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const searchInputRef = useRef(null);
  const dropdownRef = useRef(null);
  const flagUrl = 'https://country-code-au6g.vercel.app/';

  const filteredCountries = useMemo(
    () =>
      countryCodes.filter(
        ({ country_name, dial_code }) =>
          country_name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
          dial_code?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      ),
    [searchTerm]
  );

  const handleSelect = useCallback(
    dial_code => {
      onChange({ target: { name, value: dial_code } });
      setShowDropdown(false);
      setHighlightedIndex(-1);
    },
    [onChange, name]
  );

  const handleClear = e => {
    e.stopPropagation();
    onChange({ target: { name, value: '' } });
    setShowDropdown(false);
  };

  useEffect(() => {
    if (showDropdown && searchInputRef.current) {
      searchInputRef.current.focus();
      setHighlightedIndex(-1);
    }
  }, [showDropdown]);

  const handleKeyDown = e => {
    if (!showDropdown) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setHighlightedIndex(prev =>
          prev < filteredCountries?.length - 1 ? prev + 1 : 0
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setHighlightedIndex(prev =>
          prev > 0 ? prev - 1 : filteredCountries?.length - 1
        );
        break;
      case 'Enter':
        if (highlightedIndex !== -1) {
          e.preventDefault();
          handleSelect(filteredCountries?.[highlightedIndex]?.dial_code);
        }
        break;
      case 'Escape':
        setShowDropdown(false);
        break;
      default:
        break;
    }
  };

  return (
    <Dropdown
      show={showDropdown}
      onToggle={setShowDropdown}
      onKeyDown={handleKeyDown}
      ref={dropdownRef}
    >
      <Dropdown.Toggle
        variant="transparent"
        className="w-100 p-0 text-start form-tab-switch-control"
        bsPrefix="custom-dropdown-toggle"
        style={{ boxShadow: 'none', border: 'none' }}
      >
        <div style={{ position: 'relative' }}>
          <Form.Control
            name={name}
            value={value || ''}
            className={`px-2 cursor-pointer ${className}`}
            onChange={() => {}}
            isRequired={isRequired}
          />
          {value && (
            <CloseButton
              style={{
                position: 'absolute',
                top: '50%',
                right: '8px',
                transform: 'translateY(-50%)',
                width: '0.1rem',
                height: '0.1rem'
              }}
              onClick={handleClear}
            />
          )}
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="p-2 mt-1 rounded-0"
        style={{ minWidth: '250px' }}
      >
        <Form.Control
          type="text"
          placeholder="Search country..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          className="mb-2"
          autoFocus
          ref={searchInputRef}
        />
        <SimpleBar style={{ maxHeight: '200px' }}>
          {filteredCountries?.length > 0 ? (
            filteredCountries.map(
              ({ code, country_name, dial_code, image }, index) => (
                <Dropdown.Item
                  key={code}
                  onClick={() => handleSelect(dial_code)}
                  className={`d-flex align-items-center ${
                    index === highlightedIndex ? 'bg-primary text-white' : ''
                  }`}
                  style={{ cursor: 'pointer' }}
                >
                  <img
                    src={`${flagUrl}${image}`}
                    alt={country_name}
                    style={{ width: '20px', marginRight: '8px' }}
                  />
                  {Translate(country_name)} ({dial_code})
                </Dropdown.Item>
              )
            )
          ) : (
            <div className="text-center py-2 text-muted">
              {Translate('No results found')}
            </div>
          )}
        </SimpleBar>
      </Dropdown.Menu>
    </Dropdown>
  );
};

SelectCountryCode.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  isRequired: PropTypes.bool
};

export default SelectCountryCode;
