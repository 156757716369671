import React from 'react';
import { Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import SelectTermsAndCondition from 'module/Common/Search/SelectTermsAndCondition';

function TermsAndConditions({
  name,
  value,
  handleFieldChange,
  addQuotationTerms
}) {
  const Translate = useAxisproTranslate();

  return (
    <Form.Group>
      <Form.Label>{Translate('Terms & Conditions')}</Form.Label>
      <div className="d-flex">
        <div style={{ width: '20rem' }}>
          <SelectTermsAndCondition
            name={name}
            value={value}
            handleFieldChange={handleFieldChange}
            placeholder="Select Terms & Conditions"
            submit={addQuotationTerms}
            inputId="terms_and_conditions"
          />
        </div>
        <Button
          size="sm"
          onClick={addQuotationTerms}
          className="form-tab-switch-control"
        >
          {Translate('ADD')}
        </Button>
      </div>
    </Form.Group>
  );
}

TermsAndConditions.propTypes = {
  handleFieldChange: PropTypes.func,
  addQuotationTerms: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.any
};

export default TermsAndConditions;
