import { React, useContext, useState } from 'react';
import { OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import ItemDetails from 'components/item-details/ItemDetails';
import VoucherDetail from 'components/voucher-details/VoucherDetail';
import KitDetailModal from 'module/Sales/common/kitDetails/KitDetailModal';
import AssetDetails from 'components/asset-details/AssetDetails';
import { AuthWizardContext, StoreContext } from 'context/Context';

function LineItemTable({
  data,
  wo,
  batch,
  po,
  so,
  cost,
  hideViewItemOption,
  assetView,
  showPrice = false,
  exportInvoice
}) {
  const { user } = useContext(AuthWizardContext);
  const userBranchData = user?.branch;
  const enableCustomerCommission =
    userBranchData?.sales_settings?.enable_customer_commission === '1' &&
    data?.customer?.commission_enabled
      ? true
      : false;
  const { store } = useContext(StoreContext);
  const taxLabel = store?.globalConfiguration?.tax_label || 'Tax';
  const Translate = useAxisproTranslate();
  const [value, setValue] = useState({ value: 0 });
  const [voucherId, setVoucherId] = useState();
  const [showItemDetailsArea, setShowItemDetailsArea] = useState(false);
  const [batchNumberDetailsArea, setBatchNumberDetailsArea] = useState(false);
  const [showAssetDetailsArea, setShowAssetDetailsArea] = useState(false);
  const withLinteItemTaxGroup = !!(
    data?.details?.[0]?.tax_group_id?.length > 0
  );
  const permission = {
    show_item: getAcodaxPermissionSlug('IT', 'show'),
    show_batch_number: getAcodaxPermissionSlug('BCH', 'show'),
    show_asset: getAcodaxPermissionSlug('FA', 'show')
  };

  const handleItemDetailsArea = () => {
    setShowItemDetailsArea(!showItemDetailsArea);
    setValue({ value: 0 });
  };

  const handleAssetDetailsArea = () => {
    setShowAssetDetailsArea(!showAssetDetailsArea);
    setValue({ value: 0 });
  };

  const clickOnItem = id => {
    if (assetView) {
      setValue({ value: id });
      setShowAssetDetailsArea(true);
    } else {
      setValue({ value: id });
      setShowItemDetailsArea(true);
    }
  };

  const handleBatchNumberDetailsArea = () => {
    setBatchNumberDetailsArea(!batchNumberDetailsArea);
    setVoucherId('');
  };

  const clickOnBatchNumber = batch_number => {
    setVoucherId(batch_number);
    setBatchNumberDetailsArea(true);
  };

  return (
    <>
      <Table
        striped
        bordered
        hover
        responsive
        className="simple-table-animation"
      >
        <thead className="bg-dark text-light text-uppercase">
          <tr>
            <th className="fs--2 p-2 text-center">#</th>
            <th className="fs--2 p-2 text-center">{Translate('Item Code')}</th>
            {exportInvoice && (
              <th className="fs--2 p-2 text-center">{Translate('HSN Code')}</th>
            )}
            <th className="fs--2 p-2 text-start" style={{ width: '400px' }}>
              {Translate('Item Name')}
            </th>
            {wo ? (
              <th className="fs--2 p-2 text-center">
                {Translate('Warehouse')}
              </th>
            ) : (
              ''
            )}
            {wo ? (
              <th className="fs--2 p-2 text-center">
                {Translate('Description')}
              </th>
            ) : (
              ''
            )}
            {exportInvoice && (
              <th className="fs--2 p-2 text-center">
                {Translate('Country Of Origin')}
              </th>
            )}
            {batch ? (
              <th className="fs--2 p-2 text-center">{Translate('Batch')}</th>
            ) : (
              ''
            )}
            <th className="fs--2 p-2 text-center">{Translate('Quantity')}</th>
            {po || so ? (
              <th className="fs--2 p-2 text-center">
                {Translate('Quantity Received')}
              </th>
            ) : (
              ''
            )}
            <th className="fs--2 p-2 text-center">{Translate('Unit')}</th>
            {withLinteItemTaxGroup && (
              <th className="fs--2 p-2 text-center">
                {Translate('Tax Group')}
              </th>
            )}
            {!showPrice ? (
              <th className="fs--2 p-2 text-center">
                {Translate('Price')} &nbsp;
                {data && data.tax_included && data.tax_included == 1 && !wo
                  ? `(Inc.${taxLabel})`
                  : ''}
              </th>
            ) : null}
            {cost ? (
              <th className="fs--2 p-2 text-center">
                {Translate('Additional Cost')}
              </th>
            ) : (
              ''
            )}
            {data?.estimation_id && data?.trans_type === 'SQ' ? (
              <th className="fs--2 p-2 text-center">{Translate('Margin')}</th>
            ) : null}
            {wo || showPrice ? (
              ''
            ) : (
              <th className="fs--2 p-2 text-center">{Translate(taxLabel)}</th>
            )}
            {cost ? (
              <th className="fs--2 p-2 text-center">
                {Translate(`Additional Cost ${taxLabel}`)}
              </th>
            ) : (
              ''
            )}

            {wo || showPrice ? (
              ''
            ) : (
              <th className="fs--2 p-2 text-center">{Translate('Discount')}</th>
            )}
            {enableCustomerCommission && (
              <th className="fs--2 p-2 text-center" style={{ width: '130px' }}>
                {Translate('Commission')}
              </th>
            )}
            {!showPrice ? (
              <th className="fs--2 p-2 text-center">
                {Translate('Total')} &nbsp;
                {data && data.tax_included && data.tax_included == 1 && !wo
                  ? `(Inc.${taxLabel})`
                  : ''}
              </th>
            ) : null}
          </tr>
        </thead>
        {data.details?.map((item, key) => (
          <tbody key={key} className="text-dark">
            <tr>
              <td className="p-2 text-center">{key + 1}</td>
              {hideViewItemOption ? (
                <td className="p-2 text-center">{item.stock_id}</td>
              ) : (
                <td className="p-2 text-center">
                  {!item?.is_kit || item?.is_kit == 0 ? (
                    permission.show_item || permission.show_asset ? (
                      <a href="#" onClick={() => clickOnItem(item.item_id)}>
                        {item.stock_id}
                      </a>
                    ) : (
                      item.stock_id
                    )
                  ) : (
                    <KitDetailModal
                      kitItems={item.kit_items}
                      readOnly={true}
                      kit={item}
                      batch={true}
                    >
                      {item.stock_id}
                    </KitDetailModal>
                  )}
                </td>
              )}
              {exportInvoice && (
                <td className="p-2 text-center">{item?.hsn_code ?? '-'}</td>
              )}
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip>
                    <span className="fs--2">{item.item_display_name}</span>
                  </Tooltip>
                }
              >
                <td className="text-dark p-1 d-flex flex-column gap-2">
                  <div className="fw-bold fs--1">
                    {item.item_display_name.substring(0, 50)}
                  </div>
                  {
                    <div className="fs--1 text-mute ps-3">
                      {item.item_description
                        ? ' - ' + item.item_description &&
                          item.item_description
                            .match(/.{1,70}/g)
                            .map((chunk, index) => (
                              <span key={index}>
                                {chunk}
                                <br />
                              </span>
                            ))
                        : item.description
                        ? ' - ' + item.description &&
                          item.description
                            .match(/.{1,70}/g)
                            .map((chunk, index) => (
                              <span key={index}>
                                {chunk}
                                <br></br>
                              </span>
                            ))
                        : ''}
                    </div>
                  }
                </td>
              </OverlayTrigger>
              {exportInvoice && (
                <td className="p-2 text-center">
                  {item?.country_of_origin ?? '-'}
                </td>
              )}
              {batch ? (
                <td className="p-2 text-center">
                  {permission.show_batch_number ? (
                    item.batch_number_display ? (
                      <a
                        href="#"
                        onClick={() => clickOnBatchNumber(item.batch_number)}
                      >
                        {item.batch_number_display}
                      </a>
                    ) : item.batch_number ? (
                      <a
                        href="#"
                        onClick={() => clickOnBatchNumber(item.batch_number)}
                      >
                        {item.batch_number}
                      </a>
                    ) : (
                      '-'
                    )
                  ) : item.batch_number_display ? (
                    item.batch_number_display
                  ) : item.batch_number ? (
                    item.batch_number
                  ) : (
                    '-'
                  )}
                </td>
              ) : (
                ''
              )}
              {wo ? (
                <td className="p-2 text-center">{item.warehouse_name}</td>
              ) : (
                ''
              )}
              {wo ? (
                <td className="p-2 text-center">{item.description}</td>
              ) : (
                ''
              )}
              <td className="p-2 text-center">{item.quantity}</td>
              {po || so ? (
                <td className="p-2 text-center">{item.qty_sent}</td>
              ) : (
                ''
              )}
              <td className="p-2 text-center">{item.unit_name}</td>
              {withLinteItemTaxGroup && (
                <td className="p-2 text-center">{item?.tax_group_name}</td>
              )}
              {!showPrice ? (
                <td className="p-2 text-center">
                  {item.initial_unit_price_formatted ??
                    item.unit_price_formatted}
                </td>
              ) : null}
              {cost && (
                <td className="p-2 text-center">
                  {item?.additional_cost_amount_formatted}
                </td>
              )}
              {data?.estimation_id && data?.trans_type === 'SQ' ? (
                <td className="p-2 text-center">
                  {item?.margin_type === 'PERCENT'
                    ? item?.unit_margin_percent + '%'
                    : item?.unit_margin_amount_formatted}
                </td>
              ) : null}
              {wo || showPrice ? (
                ''
              ) : (
                <td className="p-2 text-center">{item.unit_tax_formatted}</td>
              )}
              {cost && (
                <td className="p-2 text-center">
                  {item?.additional_cost_tax_amount_formatted}
                </td>
              )}
              {wo || showPrice ? (
                ''
              ) : (
                <td className="p-2 text-center">
                  {item.discount_amount_formatted}
                </td>
              )}
              {enableCustomerCommission && (
                <td className="p-2 text-center">
                  {item?.customer_commission_amount_formatted}
                </td>
              )}
              {wo || showPrice ? (
                ''
              ) : (
                <td className="p-2 text-center">{item.line_total_formatted}</td>
              )}
              {wo ? (
                <td className="p-2 text-center">
                  {parseFloat(item.quantity * item.unit_price).toLocaleString(
                    navigator.language,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }
                  )}
                </td>
              ) : (
                ''
              )}
            </tr>
          </tbody>
        ))}
      </Table>
      <ItemDetails
        show={showItemDetailsArea}
        onHide={handleItemDetailsArea}
        value={value}
        showItemDetailsArea={showItemDetailsArea}
      />
      <AssetDetails
        show={showAssetDetailsArea}
        onHide={handleAssetDetailsArea}
        value={value}
        showAssetDetailsArea={showAssetDetailsArea}
      />
      <VoucherDetail
        show={batchNumberDetailsArea}
        onHide={handleBatchNumberDetailsArea}
        setShowVoucherDetailArea={setBatchNumberDetailsArea}
        type={'BCH'}
        voucherId={voucherId}
        offCanvas
      />
    </>
  );
}

LineItemTable.propTypes = {
  data: PropTypes.any,
  wo: PropTypes.bool,
  batch: PropTypes.bool,
  po: PropTypes.bool,
  so: PropTypes.bool,
  cost: PropTypes.bool,
  hideViewItemOption: PropTypes.bool,
  showPrice: PropTypes.bool,
  assetView: PropTypes.bool,
  exportInvoice: PropTypes.bool
};

export default LineItemTable;
