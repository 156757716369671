import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import { StoreContext } from 'context/Context';

export default function EntriesPopUpLineItemAccordionViewTitleSection({
  type,
  taxIncluded,
  additionalCostIncluded,
  enableTaxGroup,
  enableBatchSection,
  showQantityReceivedSection,
  ...rest
}) {
  const Translate = useAxisproTranslate();
  const { store } = useContext(StoreContext);
  const taxLabel = store?.globalConfiguration?.tax_label || 'Tax';
  return (
    <tr {...rest}>
      <th className="fw-bold py-2 border-0">{Translate('SL')}</th>
      <th className="fw-bold py-2 border-0">{Translate('Item Code')}</th>
      <th className="fw-bold py-2 border-0">{Translate('Item Name')}</th>
      <th className="fw-bold py-2 border-0">{Translate('Quantity')}</th>
      {type === 'ESTIMATION' ? (
        <>
          <th className="fw-bold py-2 border-0">{Translate('Length')}</th>
          <th className="fw-bold py-2 border-0">{Translate('Width')}</th>
          <th className="fw-bold py-2 border-0">{Translate('Height')}</th>
          <th className="fw-bold py-2 border-0">{Translate('Weight')}</th>
        </>
      ) : (
        <>
          {showQantityReceivedSection ? (
            <th className="fw-bold py-2 border-0">
              {Translate('Quantity Received')}
            </th>
          ) : null}
          <th className="fw-bold py-2 border-0">{Translate('Unit Name')}</th>
          <th className="fw-bold py-2 border-0">
            <div className="d-flex flex-row align-items-center gap-1">
              <span>{Translate('Unit Price')}</span>
              {taxIncluded ? (
                <Badge bg="warning" className="rounded">
                  <small>{Translate(`Inc ${taxLabel}`)}</small>
                </Badge>
              ) : null}
            </div>
          </th>
          {additionalCostIncluded ? (
            <th className="fw-bold py-2 border-0">
              {Translate('Additional Cost')}
            </th>
          ) : null}

          <th className="fw-bold py-2 border-0">
            {Translate(`Unit ${taxLabel}`)}
          </th>
          {additionalCostIncluded ? (
            <th className="fw-bold py-2 border-0">
              {Translate(`Additional Cost ${taxLabel}`)}
            </th>
          ) : null}
          <th className="fw-bold py-2 border-0">
            {Translate('Discount / Unit')}
          </th>

          {enableTaxGroup ? (
            <th className="fw-bold py-2 border-0">{Translate('Tax Group')}</th>
          ) : null}

          {enableBatchSection ? (
            <th className="fw-bold py-2 border-0">{Translate('Batch')}</th>
          ) : null}
        </>
      )}

      {type !== 'ESTIMATION' ? (
        <th className="fw-bold py-2 border-0">{Translate('Total')}</th>
      ) : null}

      <th className="border-0"></th>
    </tr>
  );
}

EntriesPopUpLineItemAccordionViewTitleSection.propTypes = {
  type: PropTypes.oneOf(['FIXED-ASSET']).isRequired,
  taxIncluded: PropTypes.bool,
  additionalCostIncluded: PropTypes.bool,
  enableTaxGroup: PropTypes.bool,
  enableBatchSection: PropTypes.bool,
  showQantityReceivedSection: PropTypes.bool
};
