import generateFormDataHelper from 'helpers/generateFormDataHelper';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Modal, Spinner, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ErrorAlert from 'module/Common/Error/ErrorAlert';
import axios from 'axios';
import { FaRegMinusSquare } from 'react-icons/fa';
import isObject from 'helpers/isObject';
import { showToast } from 'module/Common/Toast/toast';
import AppDatePicker from 'components/app-date-picker/AppDatePicker';
import { RiFileList3Fill } from 'react-icons/ri';
import { MdOutlineAddBox } from 'react-icons/md';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import BOMForm from 'module/Common/ViewPages/Item/BOM/BOMForm';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';
import { BsPencilSquare } from 'react-icons/bs';

export default function WorkOrderBomModal({
  onHide,
  stockId,
  workOrderId,
  workOrderDetailId
}) {
  const Translate = useAxisproTranslate();
  const formKeys = {
    trans_date: '',
    details: [],
    memo: '',
    work_order_id: workOrderId,
    work_order_detail_id: workOrderDetailId
  };

  const [formData, setFormData] = useState(formKeys);
  const [formError, setFormError] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [bomData, setBomData] = useState([]);
  const [loading, setLoading] = useState(false);
  const checkBoxStyle = { width: '14px', height: '14px' };
  const bomDataLength = bomData?.length;
  const fieldFormKeys = {
    id: '',
    item_display_name: '',
    item_display_name_ref: '',
    quantity: '',
    stock_id: '-',
    amount: '',
    unit_name: 'PCS'
  };
  const [fieldData, setFieldData] = useState(fieldFormKeys);
  const [isUpdate, setIsUpdate] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();
    setFormError({});

    if (!formData?.details?.length) {
      return showToast('Please add items to the list.', 'error');
    }

    setIsSaving(true);

    const cleanedFormData = {
      ...formData,
      details: formData?.details?.map(item => _.omit(item, ['id']))
    };

    try {
      const { data } = await axios.post(
        'purchase/material-request',
        cleanedFormData
      );

      if (data.success) {
        showToast(data.message, 'success');
        onHide();
      } else {
        showToast(data.message || 'Submission failed.', 'error');
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        'Something went wrong, please refresh the page and try again.';
      showToast(errorMessage, 'error');
    } finally {
      setIsSaving(false);
    }
  };

  const getBillOfMaterialsData = useCallback(async () => {
    if (!stockId) return;
    try {
      setLoading(true);
      const { data } = await axios.get(
        `inventory/item-wise-bill-of-materials/${stockId}`
      );
      if (data?.success && Array.isArray(data?.data)) {
        setBomData(data?.data?.filter(item => item?.product_type === 'goods'));
      }
    } catch (error) {
      console.error('Error fetching Bill of Materials:', error);
    } finally {
      setLoading(false);
    }
  }, [stockId]);

  useEffect(() => {
    getBillOfMaterialsData();

    return () => {
      setBomData([]);
      setFormError({});
    };
  }, [stockId, getBillOfMaterialsData]);

  const handleAddOrRemoveToMaterialRequestes = data => {
    setFormError({});
    if (!data || !isObject(data)) return;

    setFormData(prev => {
      const { details = [] } = prev;
      const isDuplicate = details.some(item => item.id === data.id);

      return {
        ...prev,
        details: isDuplicate
          ? details.filter(item => item.id !== data.id)
          : [
              ...details,
              {
                stock_id: data.stock_id,
                id: data.id,
                item_display_name: data.item_name,
                quantity: data.quantity,
                unit_price: data.unit_price,
                unit_name: data.unit_name
              }
            ]
      };
    });
  };

  const handleFieldChange = (e, action) => {
    if (e.target.name === 'trans_date') {
      let newFormData = generateFormDataHelper(e, action, formData);
      setFormData(newFormData);
    } else {
      let newFormData = generateFormDataHelper(e, action, fieldData);
      setFieldData(newFormData);
    }
  };

  const handleCheckAll = e => {
    if (e.target.checked) {
      setFormData(prev => ({
        ...prev,
        details: bomData?.map(item => ({
          stock_id: item?.stock_id,
          id: item?.id,
          item_display_name: item?.item_name,
          quantity: item?.quantity,
          unit_price: item?.unit_price
        }))
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        details: []
      }));
    }
  };

  const handleItem = (event, action) => {
    if (action?.action === 'select-option') {
      setFieldData(prev => ({
        ...prev,
        stock_id: event?.value,
        item_display_name: event?.label,
        item_display_name_ref: event,
        quantity: 1,
        amount: event?.price || '',
        unit_name: event?.unit_name || ''
      }));
    } else if (action?.action === 'clear') {
      setFieldData(fieldFormKeys);
    }
  };

  const handleAddLineItem = () => {
    const errors = {};

    if (!fieldData?.item_display_name) {
      errors.item_display_name = ['The item field is required.'];
    }
    if (!Number(fieldData?.quantity)) {
      errors.quantity = ['The quantity field is required.'];
    }
    if (!Number(fieldData?.amount)) {
      errors.amount = ['The unit price field is required.'];
    }

    if (Object.keys(errors).length) {
      setFormError(errors);
      return;
    }

    if (isUpdate) {
      setBomData(prev =>
        prev.map(item =>
          item.id === fieldData?.id
            ? {
                ...item,
                item_name: fieldData?.item_display_name,
                unit_price: fieldData?.amount,
                unit_name: fieldData?.unit_name,
                quantity: fieldData?.quantity,
                stock_id: fieldData?.stock_id,
                isEdit: false
              }
            : item
        )
      );
      setIsUpdate(false);
      setFieldData(fieldFormKeys);
      setIsUpdate(false);
      setFormError({});
    } else {
      const data = {
        ...fieldData,
        id: `temp${uuid()}`,
        item_name: fieldData?.item_display_name,
        unit_price: fieldData?.amount,
        unit_name: fieldData?.unit_name
      };
      setBomData(prev => [...prev, data]);
      handleAddOrRemoveToMaterialRequestes(data);
      setFieldData(fieldFormKeys);
      setFormError({});
    }
  };

  const handleEditLineItem = data => {
    if (!data) return;
    setFormError({});
    setIsUpdate(true);
    setFieldData({
      id: data.id,
      item_display_name: data.item_name,
      item_display_name_ref: {
        label: data.item_name,
        value: data.stock_id
      },
      quantity: data.quantity,
      stock_id: data.stock_id,
      amount: data.unit_price,
      unit_name: data.unit_name
    });
    setBomData(prev =>
      prev.map(item =>
        item.id === data.id
          ? { ...data, isEdit: true }
          : { ...item, isEdit: false }
      )
    );
  };

  const handleCloseLineItem = () => {
    setBomData(prev => prev.map(item => ({ ...item, isEdit: false })));
    setIsUpdate(false);
    setFieldData(fieldFormKeys);
  };

  return (
    <Modal size="xl" show={true} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title className="text-uppercase d-flex flex-row align-items-center gap-1 fw-bold">
          <RiFileList3Fill size={18} className="text-success" />
          {Translate('Bill of Materials (BOM)')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {typeof formError === 'object' && Object.keys(formError).length > 0 && (
          <ErrorAlert formError={formError} setFormError={setFormError} />
        )}

        <Form onSubmit={handleSubmit}>
          <Form.Group
            className="mb-4 d-flex flex-wrap gap-4 ms-1"
            controlId="form.date"
          >
            <Form.Label className="require-data">
              {Translate('Date')}
            </Form.Label>
            <div
              className="flex-fill d-flex flex-row align-items-center"
              style={{
                maxWidth: 300
              }}
            >
              <div className="flex-fill">
                <AppDatePicker
                  name="trans_date"
                  value={formData.trans_date}
                  yearPlaceholder="yyyy"
                  monthPlaceholder="mm"
                  dayPlaceholder="dd"
                  onChange={handleFieldChange}
                  showDefaultDate
                />
              </div>
            </div>
          </Form.Group>

          <Table
            striped
            bordered
            hover
            responsive
            className={`simple-table-animation ${
              loading && 'custom-table-cell-loader'
            }`}
          >
            {!loading && (
              <>
                <thead className="bg-secondary text-light text-uppercase">
                  <tr>
                    <th
                      className="fs--2 p-2 text-center"
                      style={{ width: '5%' }}
                    >
                      <div className="d-flex align-items-center justify-content-center gap-2">
                        {bomDataLength > 0 && (
                          <Form.Check.Input
                            type="checkbox"
                            className="m-0"
                            title="Select All"
                            onChange={handleCheckAll}
                            checked={
                              formData?.details?.length === bomDataLength
                            }
                            style={checkBoxStyle}
                          />
                        )}
                        <span>SL</span>
                      </div>
                    </th>
                    <th
                      className="fs--2 p-2 text-center"
                      style={{ width: '11%' }}
                    >
                      ITEM CODE
                    </th>
                    <th
                      className="fs--2 p-2 text-center"
                      style={{ width: '35%' }}
                    >
                      ITEM Name
                    </th>
                    <th
                      className="fs--2 p-2 text-center"
                      style={{ width: '17%' }}
                    >
                      QUANTITY
                    </th>
                    <th className="fs--2 p-2 text-center">UNIT PRICE</th>
                    <th className="fs--2 p-2 text-center">TOTAL AMOUNT</th>
                    <th className="fs--2 p-2 text-center">ACTION</th>
                  </tr>
                </thead>
                <tbody className="text-dark">
                  {bomDataLength > 0 ? (
                    <>
                      {bomData?.map((item, index) => (
                        <>
                          {!item?.isEdit ? (
                            <tr key={index}>
                              <td className="p-2 text-center">
                                <div className="d-flex align-items-center justify-content-center gap-2">
                                  <Form.Check.Input
                                    type="checkbox"
                                    className="m-0"
                                    checked={formData?.details?.some(
                                      data => data?.id === item?.id
                                    )}
                                    onChange={() =>
                                      handleAddOrRemoveToMaterialRequestes(item)
                                    }
                                    style={checkBoxStyle}
                                  />
                                  <span className="pt-1">{index + 1}</span>
                                </div>
                              </td>
                              <td className="p-2 text-center">
                                {item?.stock_id}
                              </td>
                              <td className="p-2 text-center">
                                {item?.item_name}
                              </td>
                              <td className="p-2 text-center">
                                {item?.quantity} {item?.unit_name}
                              </td>
                              <td className="p-2 text-center">
                                {item?.unit_price}
                              </td>
                              <td className="p-2 text-center">
                                {parseFloat(item?.quantity) *
                                  parseFloat(item?.unit_price)}
                              </td>
                              <td className="p-2 text-center">
                                <div className="d-flex align-items-center justify-content-center gap-2">
                                  {formData?.details?.find(
                                    data => data?.id === item?.id
                                  ) ? (
                                    <Button
                                      variant="transparent"
                                      size="sm"
                                      className="p-0 shadow-none"
                                      title="Remove from material requestes"
                                      onClick={() =>
                                        handleAddOrRemoveToMaterialRequestes(
                                          item
                                        )
                                      }
                                    >
                                      <FaRegMinusSquare
                                        className="text-danger"
                                        size={17}
                                      />
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="transparent"
                                      size="sm"
                                      className="p-0 shadow-none"
                                      title="Add to material requestes"
                                      onClick={() =>
                                        handleAddOrRemoveToMaterialRequestes(
                                          item
                                        )
                                      }
                                    >
                                      <MdOutlineAddBox
                                        className="text-success"
                                        size={19}
                                      />
                                    </Button>
                                  )}
                                  <Button
                                    variant="transparent"
                                    size="sm"
                                    className="p-0 shadow-none"
                                    title="Edit Item"
                                    onClick={() => handleEditLineItem(item)}
                                  >
                                    <BsPencilSquare
                                      className="text-warning"
                                      size={16}
                                    />
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          ) : (
                            <BOMForm
                              itemFromData={fieldData}
                              handleFieldChange={handleFieldChange}
                              handleItem={handleItem}
                              handleAddLineItem={handleAddLineItem}
                              isUpdate={isUpdate}
                              handleCloseLineItem={handleCloseLineItem}
                            />
                          )}
                        </>
                      ))}
                      {!isUpdate && (
                        <BOMForm
                          itemFromData={fieldData}
                          handleFieldChange={handleFieldChange}
                          handleItem={handleItem}
                          handleAddLineItem={handleAddLineItem}
                        />
                      )}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={7} className="text-center">
                        {Translate('No data found')}
                      </td>
                    </tr>
                  )}
                </tbody>
              </>
            )}
          </Table>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {!isSaving ? (
          <Button variant="danger" size="sm" onClick={onHide}>
            {Translate('Cancel')}
          </Button>
        ) : null}
        {bomDataLength > 0 && (
          <Button
            variant="success"
            size="sm"
            onClick={handleSubmit}
            disabled={isSaving}
            className="d-flex flex-row gap-1 align-items-center justify-content-center"
          >
            {isSaving ? (
              <>
                <Spinner
                  style={{
                    width: 15,
                    height: 15
                  }}
                />{' '}
                {Translate('Saving')}
              </>
            ) : (
              Translate('Save')
            )}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

WorkOrderBomModal.propTypes = {
  onHide: PropTypes.func,
  workOrderId: PropTypes.string,
  workOrderDetailId: PropTypes.string,
  setTabApiCallCompleted: PropTypes.func,
  WorkOrderDetailsArray: PropTypes.array,
  stockId: PropTypes.string
};
