import { Fragment, React } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import {
  FaCheckCircle,
  FaPlusCircle,
  FaShippingFast,
  FaSignOutAlt
} from 'react-icons/fa';
import WorkOrderEntryActionButton from 'components/work-order-entry-action-button/WorkOrderEntryActionButton';
import WorkOrderLineItemTaskItem from './components/WorkOrderLineItemTaskItem';
import QuillPreviewContainer from 'components/QuillPreviewContainer/QuillPreviewContainer';
import ShowDimensionsResult from 'module/Production/WorkOrder/components/ShowDimensionsResult';

function WorkOrderView({
  workOrderVoucherData,
  setTabApiCallCompleted,
  setWorkOrderVoucherData
}) {
  const Translate = useAxisproTranslate();
  return (
    <Container className="mt-3 ps-3 pe-3 voucher-inquiry-view-wrapper">
      <Row>
        <Col xl={12} md={12} sm={12} className="p-0 mt-2">
          <Table
            striped
            bordered
            hover
            responsive
            className="simple-table-animation"
          >
            <thead className="bg-dark text-light text-uppercase">
              <tr>
                <th className="fs--2 p-2 text-center">#</th>
                <th className="fs--2 p-2 text-center">
                  {Translate('Item Code')}
                </th>
                <th className="fs--2 p-2">{Translate('Item Name')}</th>
                <th className="fs--2 p-2">{Translate('Unit')}</th>
                <th className="fs--2 p-2 text-center">
                  {Translate('Dimension(L*W*H)')}
                </th>
                <th className="fs--2 p-2 text-center">{Translate('Weight')}</th>
                <th className="fs--2 p-2 text-center">
                  {Translate('Total Qty')}
                </th>
                <th className="fs--2 p-2 text-center">
                  {Translate('Released Qty')}
                </th>
                <th className="fs--2 p-2 text-center">
                  {Translate('Delivered Qty')}
                </th>
                <th className="fs--2 p-2 text-center">
                  {Translate('Actions')}
                </th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(workOrderVoucherData?.details)
                ? workOrderVoucherData.details.map((item, index) => {
                    const releaselimit = !isNaN(
                      parseFloat(item?.producing_quantity)
                    )
                      ? item.producing_quantity
                      : 0;
                    const disableRelease = !!(releaselimit < 1);
                    const deliverylimit =
                      (!isNaN(parseFloat(item?.released_quantity))
                        ? item.released_quantity
                        : 0) -
                      (!isNaN(parseFloat(item?.delivered_quantity))
                        ? item.delivered_quantity
                        : 0);

                    const fullyDelivered =
                      item?.released_quantity?.toString() ===
                        item?.delivered_quantity?.toString() &&
                      releaselimit <= 0;

                    return (
                      <Fragment key={index}>
                        <tr>
                          <td className="p-2 text-center">
                            <TableLineItemColumTitle
                              show={item?.task && index > 0}
                              title="#"
                            />
                            {index + 1}
                          </td>
                          <td className="p-2 text-center">
                            <TableLineItemColumTitle
                              show={item?.task && index > 0}
                              title={Translate('ITEM CODE')}
                            />
                            {item?.stock_id ?? '-'}
                          </td>
                          <td className="p-2">
                            <TableLineItemColumTitle
                              show={item?.task && index > 0}
                              title={Translate('ITEM NAME')}
                            />
                            {item?.item_display_name ?? '-'}
                          </td>
                          <td className="p-2">
                            <TableLineItemColumTitle
                              show={item?.task && index > 0}
                              title={Translate('UNIT')}
                            />
                            {item?.unit_name ?? '-'}
                          </td>
                          <td className="p-2 text-center">
                            <TableLineItemColumTitle
                              show={item?.task && index > 0}
                              title={Translate('Dimension(L*W*H)')}
                            />
                            <ShowDimensionsResult entry={item} />
                          </td>
                          <td className="p-2 text-center">
                            <TableLineItemColumTitle
                              show={item?.task && index > 0}
                              title={Translate('Weight')}
                            />
                            {item?.weight || '-'}
                          </td>
                          <td className="p-2">
                            <TableLineItemColumTitle
                              show={item?.task && index > 0}
                              title={Translate('TOTAL QTY')}
                            />
                            {item?.quantity ?? '-'}
                          </td>
                          <td className="p-2">
                            <TableLineItemColumTitle
                              show={item?.task && index > 0}
                              title={Translate('RELEASED QTY')}
                            />
                            {item?.released_quantity ?? '-'}
                          </td>
                          <td className="p-2">
                            <TableLineItemColumTitle
                              show={item?.task && index > 0}
                              title={Translate('DELIVERED QTY')}
                            />
                            {item?.delivered_quantity ?? '-'}
                          </td>
                          <td className="p-2 ps-3 pe-3">
                            <div className="d-flex flex-wrap gap-1 align-items-center justify-content-start">
                              <WorkOrderEntryActionButton
                                keyName="materialRequest"
                                workOrderId={workOrderVoucherData?.id}
                                workOrderDetailId={item?.id}
                                setTabApiCallCompleted={setTabApiCallCompleted}
                                disabled={disableRelease}
                                workOrderItem={{
                                  estimation_id:
                                    workOrderVoucherData?.estimation_id,
                                  stock_id: item?.stock_id,
                                  dimensions: [
                                    item?.dim_length,
                                    item?.dim_width,
                                    item?.dim_height
                                  ]
                                }}
                              >
                                <FaPlusCircle size={12} />
                                <small>Material Requests</small>
                              </WorkOrderEntryActionButton>
                              <WorkOrderEntryActionButton
                                variant="danger"
                                keyName="additionalCosts"
                                workOrderDetailId={item?.id}
                                setTabApiCallCompleted={setTabApiCallCompleted}
                                disabled={disableRelease}
                              >
                                <FaPlusCircle size={12} />
                                <small>Additional costs</small>
                              </WorkOrderEntryActionButton>
                              <WorkOrderEntryActionButton
                                variant="info"
                                keyName="bom"
                                workOrderId={workOrderVoucherData?.id}
                                workOrderDetailId={item?.id}
                                stockId={item?.stock_id}
                                setTabApiCallCompleted={setTabApiCallCompleted}
                                disabled={disableRelease}
                                title="Bill of Materials"
                              >
                                <FaPlusCircle size={12} />
                                <small>BOM</small>
                              </WorkOrderEntryActionButton>
                              <WorkOrderEntryActionButton
                                variant="warning"
                                keyName="release"
                                workOrderDetailId={item?.id}
                                setTabApiCallCompleted={setTabApiCallCompleted}
                                setWorkOrderVoucherData={
                                  setWorkOrderVoucherData
                                }
                                releaselimit={releaselimit}
                                disabled={disableRelease}
                              >
                                {!disableRelease ? (
                                  <>
                                    <FaSignOutAlt size={13} />
                                    <small>Release</small>
                                  </>
                                ) : (
                                  <>
                                    <FaCheckCircle size={13} />
                                    <small>Fully Released</small>
                                  </>
                                )}
                              </WorkOrderEntryActionButton>
                              <WorkOrderEntryActionButton
                                variant="success"
                                keyName="delivery"
                                workOrderDetailId={item?.id}
                                setTabApiCallCompleted={setTabApiCallCompleted}
                                setWorkOrderVoucherData={
                                  setWorkOrderVoucherData
                                }
                                deliverylimit={deliverylimit}
                                disabled={deliverylimit <= 0 || fullyDelivered}
                              >
                                {!fullyDelivered ? (
                                  <>
                                    <FaShippingFast size={13} />
                                    <small>Delivery</small>
                                  </>
                                ) : (
                                  <>
                                    <FaCheckCircle size={13} />
                                    <small>Fully Delivered</small>
                                  </>
                                )}
                              </WorkOrderEntryActionButton>
                            </div>
                          </td>
                        </tr>
                        <WorkOrderLineItemTaskItem
                          isCompleted={disableRelease}
                          task={item?.task}
                          itemTargetId={item?.id}
                          setWorkOrderVoucherData={setWorkOrderVoucherData}
                          withBottomSpace
                        />
                      </Fragment>
                    );
                  })
                : null}
            </tbody>
          </Table>
        </Col>
      </Row>

      {workOrderVoucherData?.description ? (
        <div className="mt-4">
          <QuillPreviewContainer
            title="Description :"
            value={workOrderVoucherData.description}
            dashedBorder
          />
        </div>
      ) : null}
    </Container>
  );
}

const TableLineItemColumTitle = ({ show, title }) => {
  return show ? (
    <>
      <span
        className="text-uppercase text-muted"
        style={{
          fontSize: '0.5rem'
        }}
      >
        {title}
      </span>
      <br />
    </>
  ) : null;
};

WorkOrderView.propTypes = {
  workOrderVoucherData: PropTypes.any,
  setTabApiCallCompleted: PropTypes.func,
  setWorkOrderVoucherData: PropTypes.func
};

TableLineItemColumTitle.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string
};

export default WorkOrderView;
