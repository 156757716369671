import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import useAxisproTranslate from 'hooks/useAxisproTranslate';

const PurchaseFormSaveButton = ({
  size,
  className,
  variant,
  spinnerVariant,
  spinnerSize,
  spinnerClassName,
  onSave,
  draftIsSaving,
  text,
  onSaveText,
  onDraftText,
  ...rest
}) => {
  const Translate = useAxisproTranslate();
  return (
    <Button
      size={size ?? 'sm'}
      className={`form-tab-switch-control w-10 d-flex align-items-center justify-content-center ${
        className ?? ''
      }`}
      type="submit"
      variant={variant ?? 'success'}
      disabled={onSave || draftIsSaving}
      {...rest}
    >
      {onSave || draftIsSaving ? (
        <>
          <Spinner
            as="span"
            animation="border"
            size={spinnerSize ?? 'sm'}
            role="status"
            aria-hidden="true"
            variant={spinnerVariant ?? 'white'}
            className={`me-1 ${spinnerClassName}`}
          />
          <span className="fs--1">
            {draftIsSaving
              ? Translate(onDraftText ?? 'Saving draft...')
              : Translate(onSaveText ?? 'Saving...')}
          </span>
        </>
      ) : (
        <span>{Translate(text ?? 'Save')}</span>
      )}
    </Button>
  );
};

PurchaseFormSaveButton.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
  spinnerVariant: PropTypes.string,
  spinnerSize: PropTypes.string,
  spinnerClassName: PropTypes.string,
  onSave: PropTypes.bool.isRequired,
  draftIsSaving: PropTypes.bool,
  text: PropTypes.string,
  onSaveText: PropTypes.string,
  onDraftText: PropTypes.string
};

export default PurchaseFormSaveButton;
