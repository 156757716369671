import { React, useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FcUpload } from 'react-icons/fc';

import { showToast } from 'module/Common/Toast/toast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import FileController from 'components/file-controller/FileController';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import setNewFormData from 'helpers/setNewFormData';
import SelectDocumentType from 'components/form/SelectDocumentType';

function FileUpload({
  show,
  onHide,
  type,
  id,
  setKey,
  label,
  documents,
  data,
  fetchData,
  collection
}) {
  const Translate = useAxisproTranslate();
  const [save, setSave] = useState(false);
  const [formData, setFormData] = useState({
    type: type,
    id: id,
    files: []
  });

  const handleFieldChange = (e, action) =>
    setFormData(generateFormDataHelper(e, action, formData));

  const handleFiles = files => {
    handleFieldChange({ target: { name: 'files', value: files } });
  };

  let newFormData = setNewFormData(formData);

  let handleSubmit = () => {
    setSave(true);
    axios({
      method: 'post',
      url: 'documents',
      data: newFormData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(res => {
        if (res.data.success === true) {
          typeof fetchData === 'function' && fetchData(false);
          setFormData({
            ...formData,
            document_type: '',
            document_type_ref: '',
            files: []
          });
          if (typeof setKey === 'function' && !documents) {
            const responseData = res?.data?.data;
            if (data && Object.keys(data).length > 0) {
              data.media.push(responseData);
            }
            if (
              collection &&
              collection === 'ITEM' &&
              responseData?.[0]?.mime_type?.includes('image')
            ) {
              setKey('images');
            } else {
              setKey('attachments');
            }
          } else {
            setKey('kyc');
          }
          onHide();
        }
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setSave(false);
      });
  };

  const submitFiles = () => {
    if (documents) {
      if (formData?.document_type && formData?.document_type !== null) {
        handleSubmit();
      } else {
        showToast('Document type is required for KYC documents!', 'error');
      }
    } else {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (type == 'ITEM') {
      setFormData(prev => ({
        ...prev,
        collection: collection ?? 'item-images'
      }));
    }
  }, [type, collection]);

  return (
    <Modal show={show} size="lg">
      <Card>
        <Card.Header className="d-flex gap-2 border-bottom align-items-center justify-content-start">
          <FcUpload size={30} />
          <h5 className="fs--1 text-uppercase text-dark fw-bold m-0">
            {Translate(label ? label : 'Upload Documents')}
          </h5>
        </Card.Header>
        <Card.Body className="pt-0 ps-4 d-flex align-items-center justify-content-start">
          <Row>
            {documents && (
              <Col className="mb-4">
                <Form.Label className="m-0 d-flex align-items-center justify-content-start h-100">
                  {Translate('Documents Type')}
                </Form.Label>
                <SelectDocumentType
                  value={formData.documents_type_ref}
                  name={'document_type'}
                  handleFieldChange={handleFieldChange}
                  setFormData={setFormData}
                  type={type}
                />
              </Col>
            )}
            <FileController
              limit={10}
              limitFeature={true}
              limitFileSizeInMB={5}
              limitFileType={[
                'jpg',
                'png',
                'jpeg',
                'gif',
                'pdf',
                'excel',
                'xlsx',
                'docx',
                'doc'
              ]}
              onChange={handleFiles}
              className="my-3"
              note={Translate('You can upload a maximum of 10 files, 5MB each')}
            />
          </Row>
        </Card.Body>
        <Card.Footer className="d-flex gap-2 align-items-center justify-content-end">
          {formData.files.length > 0 ? (
            <Button
              variant="success"
              style={{ boxShadow: 'none' }}
              onClick={submitFiles}
              size="sm"
              disabled={save}
            >
              {save ? 'Uploading...' : 'Upload'}
            </Button>
          ) : (
            ''
          )}

          <Button
            variant="danger"
            style={{ boxShadow: 'none' }}
            onClick={onHide}
            size="sm"
            disabled={save}
          >
            {Translate('Cancel')}
          </Button>
        </Card.Footer>
      </Card>
    </Modal>
  );
}

FileUpload.propTypes = {
  show: PropTypes.bool,
  documents: PropTypes.bool,
  onHide: PropTypes.func,
  type: PropTypes.any,
  id: PropTypes.any,
  setKey: PropTypes.func,
  label: PropTypes.string,
  data: PropTypes.any,
  fetchData: PropTypes.func,
  collection: PropTypes.string
};

export default FileUpload;
