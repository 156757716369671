import { React, useState } from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { MdOutlineQrCode2 } from 'react-icons/md';

import QrCodeModal from './QrCodeModal';

function GenerateQrCodeButton({ label, className }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        className={`qr-code-generating-button ${className}`}
        size="sm"
        variant={'transparent'}
        onClick={handleShow}
      >
        <span className="me-1 mb-0">{label}</span>
        <MdOutlineQrCode2 size={13} />
      </Button>

      <QrCodeModal show={show} handleClose={handleClose} />
    </>
  );
}

GenerateQrCodeButton.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string
};

export default GenerateQrCodeButton;
