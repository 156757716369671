import { React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Offcanvas, OffcanvasHeader, OffcanvasTitle } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import ItemDetailsCollapse from './ItemDetailsCollapse';
import ItemDetailsTab from './ItemDetailsTab';

const ItemDetails = ({ show, onHide, value, showItemDetailsArea }) => {
  const Translate = useAxisproTranslate();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`inventory/items/${value.value}`)
        .then(res => {
          if (res.data.success) {
            const responseData = res?.data?.data;
            setData({
              ...responseData,
              images: [
                ...(responseData?.images || []),
                ...(responseData?.media?.filter(item =>
                  item?.mime_type?.includes('image')
                ) || [])
              ],
              media: [
                ...(responseData?.media?.filter(
                  item => !item?.mime_type?.includes('image')
                ) || [])
              ]
            });
            setLoading(false);
          }
        })
        .catch(error => {
          console.log(error);
        });
    };

    setLoading(true);
    if (showItemDetailsArea) {
      fetchData();
    }
  }, [showItemDetailsArea, value.value]);

  return (
    <Offcanvas
      show={show}
      placement="end"
      onHide={onHide}
      style={{ zIndex: 50000 }}
    >
      <OffcanvasHeader closeButton style={{ boxShadow: 'none' }}>
        <OffcanvasTitle className="fs-1 text-dark fw-bold m-0 text-uppercase ">
          {Translate('Item Details')}
        </OffcanvasTitle>
      </OffcanvasHeader>
      {!loading && data !== '' ? (
        <Offcanvas.Body className="customer-details-offcanvas pt-0">
          <div className="customer-header d-flex flex-row align-items-center">
            <div className="profile-icon d-flex justify-content-center align-items-center me-2 shadow-sm ">
              <h5 className="letter text-uppercase m-0 text-white">
                {data && data.images && data.images[0] ? (
                  <img
                    className="rounded-circle"
                    style={{ height: '70px', width: '70px' }}
                    src={
                      data?.images?.[0]?.original_url ||
                      data?.images?.[0]?.file_url
                    }
                  ></img>
                ) : (
                  ''
                )}
              </h5>
            </div>
            <div className="details">
              <div className="d-flex pt-2">
                <h5 className="mt-1 me-2 text-uppercase">{data.item_name}</h5>
                <Link
                  className="m-0"
                  to={
                    '/inventory/items?entry=' + value.value + '&layout=column'
                  }
                >
                  <FontAwesomeIcon icon="external-link-alt" size="xs" />
                </Link>
              </div>
              <p>{data.stock_id}</p>
              <div className="d-flex mt-2">
                <h6 className="mb-0 text-muted">
                  {data.email}
                  {data.email ? <span className="me-1 mx-1">|</span> : ''}
                  {data.phone}
                </h6>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-between pt-3 pb-2">
            <div className="me-2 py-2">
              <h6 className="text-muted text-uppercase">
                {Translate('Sales Price')}
              </h6>
              <h5 className="fs--1 fw-bold text-success">
                {data.sale_rate_formatted ? data.sale_rate_formatted : ''}
              </h5>
            </div>
            {data?.product_type === 'service' ? (
              ''
            ) : (
              <div className="py-2">
                <h6 className="text-muted text-uppercase">
                  {Translate('Purchase Price')}
                </h6>
                <h5 className="fs--1 fw-bold text-danger">
                  {data.purchase_rate_formatted
                    ? data.purchase_rate_formatted
                    : ''}
                </h5>
              </div>
            )}
          </div>
          <ItemDetailsCollapse data={data} />
          {data?.product_type === 'goods' && <ItemDetailsTab data={data} />}
        </Offcanvas.Body>
      ) : (
        <LoadingScreen />
      )}
    </Offcanvas>
  );
};

ItemDetails.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  value: PropTypes.any,
  showItemDetailsArea: PropTypes.bool
};

export default ItemDetails;
